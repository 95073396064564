export const faShimmer = {
  prefix: "fab",
  iconName: "shimmer",
  icon: [
    48,
    48,
    [],
    "",
    "M 37.87,10.79 \
           C 31.85,4.40 21.97,4.27 15.80,10.48 \
             9.62,16.69 9.49,26.91 15.50,33.29 \
             15.50,33.29 19.45,29.31 19.45,29.31 \
             15.58,25.17 15.67,18.56 19.67,14.54 \
             23.67,10.52 30.06,10.60 33.96,14.72 \
             33.96,14.72 37.87,10.79 37.87,10.79 Z \
           M 11.61,37.21 \
           C 17.62,43.60 27.50,43.73 33.68,37.52 \
             39.86,31.31 39.99,21.09 33.98,14.71 \
             33.98,14.71 30.02,18.69 30.02,18.69 \
             33.90,22.83 33.81,29.44 29.81,33.46 \
             25.81,37.48 19.42,37.40 15.52,33.28 \
             15.52,33.28 11.61,37.21 11.61,37.21 Z"
]};