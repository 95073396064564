import React from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIota, faShimmer } from './custom-fa'
import {
  faLinkedin,
  faGithub,
  faStackOverflow,
  faSteam,
  faTwitter
} from '@fortawesome/free-brands-svg-icons'
import {
  faCamera,
  faFileAlt,
  faCode
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faCode,
  faIota,
  faShimmer,
  faLinkedin,
  faTwitter,
  faStackOverflow,
  faSteam,
  faGithub,
  faCamera,
  faFileAlt
)

const Icon = props => <FontAwesomeIcon {...props} />

export default Icon
