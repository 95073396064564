import PropTypes from "prop-types";
import React from "react";
import CodeTop from "../components/code-top"
import CodeBottom from "../components/code-bottom"
import { useThemeStore } from "./store";
import ThemeSwitcher from "../components/theme-switcher"


const Layout = ({ children }) => {
  const [theme] = useThemeStore();

  return (
    <div className={`${theme.theme} flex flex-col font-sans min-h-screen bg-background text-text-main`}>
      <CodeTop />
      <ThemeSwitcher />
      <main className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto p-5 pt-16 md:pt-56 w-full">
        {children}
      </main>
      <CodeBottom />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
