export const faIota = {
  prefix: "fab",
  iconName: "iota",
  icon: [
    48,
    48,
    [],
    "",
    "M 15.09,34.68 \
           C 15.73,34.68 16.25,34.16 16.25,33.52 \
             16.25,32.88 15.73,32.36 15.09,32.36 \
             14.45,32.36 13.93,32.88 13.93,33.52 \
             13.93,34.16 14.45,34.68 15.09,34.68 Z \
           M 29.46,38.15 \
           C 30.46,38.15 31.28,37.33 31.28,36.33 \
             31.28,35.33 30.46,34.52 29.46,34.52 \
             28.46,34.52 27.65,35.33 27.65,36.33 \
             27.65,37.33 28.46,38.15 29.46,38.15 Z \
           M 22.58,38.76 \
           C 23.44,38.76 24.13,38.07 24.13,37.22 \
             24.13,36.37 23.44,35.68 22.58,35.68 \
             21.73,35.68 21.04,36.37 21.04,37.22 \
             21.04,38.07 21.73,38.76 22.58,38.76 Z \
           M 26.49,42.00 \
           C 27.49,42.00 28.30,41.19 28.30,40.19 \
             28.30,39.19 27.49,38.38 26.49,38.38 \
             25.48,38.38 24.67,39.19 24.67,40.19 \
             24.67,41.19 25.48,42.00 26.49,42.00 Z \
           M 19.37,41.38 \
           C 20.23,41.38 20.92,40.69 20.92,39.84 \
             20.92,38.99 20.23,38.30 19.37,38.30 \
             18.52,38.30 17.83,38.99 17.83,39.84 \
             17.83,40.69 18.52,41.38 19.37,41.38 Z \
           M 16.71,37.99 \
           C 17.46,37.99 18.06,37.39 18.06,36.64 \
             18.06,35.90 17.46,35.29 16.71,35.29 \
             15.96,35.29 15.36,35.90 15.36,36.64 \
             15.36,37.39 15.96,37.99 16.71,37.99 Z \
           M 19.92,35.37 \
           C 20.66,35.37 21.27,34.77 21.27,34.02 \
             21.27,33.28 20.66,32.67 19.92,32.67 \
             19.17,32.67 18.56,33.28 18.56,34.02 \
             18.56,34.77 19.17,35.37 19.92,35.37 Z \
           M 25.60,34.91 \
           C 26.45,34.91 27.14,34.22 27.14,33.37 \
             27.14,32.51 26.45,31.82 25.60,31.82 \
             24.74,31.82 24.05,32.51 24.05,33.37 \
             24.05,34.22 24.74,34.91 25.60,34.91 Z \
           M 22.89,31.55 \
           C 23.64,31.55 24.25,30.95 24.25,30.21 \
             24.25,29.46 23.64,28.86 22.89,28.86 \
             22.15,28.86 21.54,29.46 21.54,30.21 \
             21.54,30.95 22.15,31.55 22.89,31.55 Z \
           M 18.29,32.06 \
           C 18.93,32.06 19.45,31.54 19.45,30.90 \
             19.45,30.26 18.93,29.74 18.29,29.74 \
             17.65,29.74 17.13,30.26 17.13,30.90 \
             17.13,31.54 17.65,32.06 18.29,32.06 Z \
           M 14.35,31.55 \
           C 14.88,31.55 15.32,31.12 15.32,30.59 \
             15.32,30.06 14.88,29.63 14.35,29.63 \
             13.82,29.63 13.38,30.06 13.38,30.59 \
             13.38,31.12 13.82,31.55 14.35,31.55 Z \
           M 14.31,28.89 \
           C 14.78,28.89 15.16,28.52 15.16,28.05 \
             15.16,27.58 14.78,27.20 14.31,27.20 \
             13.84,27.20 13.46,27.58 13.46,28.05 \
             13.46,28.52 13.84,28.89 14.31,28.89 Z \
           M 17.60,28.93 \
           C 18.13,28.93 18.56,28.50 18.56,27.97 \
             18.56,27.44 18.13,27.01 17.60,27.01 \
             17.06,27.01 16.63,27.44 16.63,27.97 \
             16.63,28.50 17.06,28.93 17.60,28.93 Z \
           M 21.31,28.20 \
           C 21.95,28.20 22.47,27.68 22.47,27.04 \
             22.47,26.41 21.95,25.89 21.31,25.89 \
             20.67,25.89 20.15,26.41 20.15,27.04 \
             20.15,27.68 20.67,28.20 21.31,28.20 Z \
           M 20.57,25.12 \
           C 21.11,25.12 21.54,24.69 21.54,24.15 \
             21.54,23.62 21.11,23.19 20.57,23.19 \
             20.04,23.19 19.61,23.62 19.61,24.15 \
             19.61,24.69 20.04,25.12 20.57,25.12 Z \
           M 17.52,26.27 \
           C 17.99,26.27 18.37,25.89 18.37,25.43 \
             18.37,24.96 17.99,24.58 17.52,24.58 \
             17.05,24.58 16.67,24.96 16.67,25.43 \
             16.67,25.89 17.05,26.27 17.52,26.27 Z \
           M 14.74,26.62\
           C 15.12,26.62 15.43,26.31 15.43,25.93 \
             15.43,25.54 15.12,25.23 14.74,25.23 \
             14.35,25.23 14.04,25.54 14.04,25.93 \
             14.04,26.31 14.35,26.62 14.74,26.62 Z \
           M 18.56,16.37 \
           C 19.31,16.37 19.92,15.76 19.92,15.02 \
             19.92,14.27 19.31,13.67 18.56,13.67 \
             17.82,13.67 17.21,14.27 17.21,15.02 \
             17.21,15.76 17.82,16.37 18.56,16.37 Z \
           M 22.08,16.33 \
           C 22.72,16.33 23.24,15.81 23.24,15.17 \
             23.24,14.53 22.72,14.02 22.08,14.02 \
             21.44,14.02 20.92,14.53 20.92,15.17 \
             20.92,15.81 21.44,16.33 22.08,16.33 Z \
           M 21.42,12.24 \
           C 22.06,12.24 22.58,11.73 22.58,11.09 \
             22.58,10.45 22.06,9.93 21.42,9.93 \
             20.78,9.93 20.26,10.45 20.26,11.09 \
             20.26,11.73 20.78,12.24 21.42,12.24 Z \
           M 17.91,12.24 \
           C 18.65,12.24 19.26,11.64 19.26,10.89 \
             19.26,10.15 18.65,9.55 17.91,9.55 \
             17.16,9.55 16.55,10.15 16.55,10.89 \
             16.55,11.64 17.16,12.24 17.91,12.24 Z \
           M 13.81,13.17 \
           C 14.66,13.17 15.36,12.48 15.36,11.63 \
             15.36,10.78 14.66,10.09 13.81,10.09 \
             12.96,10.09 12.26,10.78 12.26,11.63 \
             12.26,12.48 12.96,13.17 13.81,13.17 Z \
           M 14.47,17.25 \
           C 15.32,17.25 16.01,16.56 16.01,15.71 \
             16.01,14.86 15.32,14.17 14.47,14.17 \
             13.61,14.17 12.92,14.86 12.92,15.71 \
             12.92,16.56 13.61,17.25 14.47,17.25 Z \
           M 9.99,19.45 \
           C 10.99,19.45 11.80,18.64 11.80,17.64 \
             11.80,16.64 10.99,15.83 9.99,15.83 \
             8.98,15.83 8.17,16.64 8.17,17.64 \
             8.17,18.64 8.98,19.45 9.99,19.45 Z \
           M 7.16,27.66 \
           C 8.36,27.66 9.33,26.70 9.33,25.50 \
             9.33,24.31 8.36,23.34 7.16,23.34 \
             5.97,23.34 5.00,24.31 5.00,25.50 \
             5.00,26.70 5.97,27.66 7.16,27.66 Z \
           M 11.80,23.96 \
           C 12.80,23.96 13.62,23.15 13.62,22.15 \
             13.62,21.15 12.80,20.34 11.80,20.34 \
             10.80,20.34 9.99,21.15 9.99,22.15 \
             9.99,23.15 10.80,23.96 11.80,23.96 Z \
           M 16.28,21.76 \
           C 17.14,21.76 17.83,21.07 17.83,20.22 \
             17.83,19.37 17.14,18.68 16.28,18.68 \
             15.43,18.68 14.74,19.37 14.74,20.22 \
             14.74,21.07 15.43,21.76 16.28,21.76 Z \
           M 20.38,20.84 \
           C 21.13,20.84 21.73,20.24 21.73,19.49 \
             21.73,18.75 21.13,18.14 20.38,18.14 \
             19.63,18.14 19.03,18.75 19.03,19.49 \
             19.03,20.24 19.63,20.84 20.38,20.84 Z \
           M 23.90,20.84 \
           C 24.54,20.84 25.06,20.32 25.06,19.68 \
             25.06,19.04 24.54,18.53 23.90,18.53 \
             23.26,18.53 22.74,19.04 22.74,19.68 \
             22.74,20.32 23.26,20.84 23.90,20.84 Z \
           M 24.98,16.95 \
           C 25.51,16.95 25.94,16.52 25.94,15.98 \
             25.94,15.45 25.51,15.02 24.98,15.02 \
             24.45,15.02 24.01,15.45 24.01,15.98 \
             24.01,16.52 24.45,16.95 24.98,16.95 Z \
           M 24.32,12.86 \
           C 24.86,12.86 25.29,12.43 25.29,11.90 \
             25.29,11.37 24.86,10.93 24.32,10.93 \
             23.79,10.93 23.36,11.37 23.36,11.90 \
             23.36,12.43 23.79,12.86 24.32,12.86 Z \
           M 35.53,30.82 \
           C 36.06,30.82 36.49,30.39 36.49,29.86 \
             36.49,29.33 36.06,28.89 35.53,28.89 \
             34.99,28.89 34.56,29.33 34.56,29.86 \
             34.56,30.39 34.99,30.82 35.53,30.82 Z \
           M 26.52,13.98 \
           C 26.99,13.98 27.37,13.60 27.37,13.13 \
             27.37,12.66 26.99,12.28 26.52,12.28 \
             26.05,12.28 25.67,12.66 25.67,13.13 \
             25.67,13.60 26.05,13.98 26.52,13.98 Z \
           M 28.15,15.29 \
           C 28.53,15.29 28.84,14.98 28.84,14.60 \
             28.84,14.21 28.53,13.90 28.15,13.90 \
             27.76,13.90 27.45,14.21 27.45,14.60 \
             27.45,14.98 27.76,15.29 28.15,15.29 Z \
           M 27.22,18.06 \
           C 27.69,18.06 28.07,17.68 28.07,17.22 \
             28.07,16.75 27.69,16.37 27.22,16.37 \
             26.75,16.37 26.37,16.75 26.37,17.22 \
             26.37,17.68 26.75,18.06 27.22,18.06 Z \
           M 26.79,21.46 \
           C 27.33,21.46 27.76,21.02 27.76,20.49 \
             27.76,19.96 27.33,19.53 26.79,19.53 \
             26.26,19.53 25.83,19.96 25.83,20.49 \
             25.83,21.02 26.26,21.46 26.79,21.46 Z \
           M 26.83,28.70 \
           C 27.37,28.70 27.80,28.27 27.80,27.74 \
             27.80,27.21 27.37,26.78 26.83,26.78 \
             26.30,26.78 25.87,27.21 25.87,27.74 \
             25.87,28.27 26.30,28.70 26.83,28.70 Z \
           M 29.46,30.55 \
           C 29.93,30.55 30.31,30.17 30.31,29.70 \
             30.31,29.24 29.93,28.86 29.46,28.86 \
             28.99,28.86 28.61,29.24 28.61,29.70 \
             28.61,30.17 28.99,30.55 29.46,30.55 Z \
           M 31.28,32.56 \
           C 31.66,32.56 31.97,32.25 31.97,31.86 \
             31.97,31.48 31.66,31.17 31.28,31.17 \
             30.89,31.17 30.58,31.48 30.58,31.86 \
             30.58,32.25 30.89,32.56 31.28,32.56 Z \
           M 33.33,32.02 \
           C 33.80,32.02 34.18,31.64 34.18,31.17 \
             34.18,30.70 33.80,30.32 33.33,30.32 \
             32.86,30.32 32.48,30.70 32.48,31.17 \
             32.48,31.64 32.86,32.02 33.33,32.02 Z \
           M 31.66,29.36 \
           C 32.20,29.36 32.63,28.93 32.63,28.39 \
             32.63,27.86 32.20,27.43 31.66,27.43 \
             31.13,27.43 30.70,27.86 30.70,28.39 \
             30.70,28.93 31.13,29.36 31.66,29.36 Z \
           M 29.00,26.77 \
           C 29.64,26.77 30.16,26.26 30.16,25.62 \
             30.16,24.98 29.64,24.46 29.00,24.46 \
             28.36,24.46 27.84,24.98 27.84,25.62 \
             27.84,26.26 28.36,26.77 29.00,26.77 Z \
           M 37.69,28.93 \
           C 38.33,28.93 38.85,28.42 38.85,27.78 \
             38.85,27.14 38.33,26.62 37.69,26.62 \
             37.05,26.62 36.53,27.14 36.53,27.78 \
             36.53,28.42 37.05,28.93 37.69,28.93 Z \
           M 33.79,27.47 \
           C 34.43,27.47 34.95,26.95 34.95,26.31 \
             34.95,25.67 34.43,25.16 33.79,25.16 \
             33.15,25.16 32.63,25.67 32.63,26.31 \
             32.63,26.95 33.15,27.47 33.79,27.47 Z \
           M 39.62,26.16 \
           C 40.37,26.16 40.98,25.55 40.98,24.81 \
             40.98,24.06 40.37,23.46 39.62,23.46 \
             38.88,23.46 38.27,24.06 38.27,24.81 \
             38.27,25.55 38.88,26.16 39.62,26.16 Z \
           M 30.89,24.00 \
           C 31.64,24.00 32.24,23.40 32.24,22.65 \
             32.24,21.91 31.64,21.30 30.89,21.30 \
             30.14,21.30 29.54,21.91 29.54,22.65 \
             29.54,23.40 30.14,24.00 30.89,24.00 Z \
           M 35.72,24.66 \
           C 36.47,24.66 37.07,24.05 37.07,23.31 \
             37.07,22.56 36.47,21.96 35.72,21.96 \
             34.97,21.96 34.37,22.56 34.37,23.31 \
             34.37,24.05 34.97,24.66 35.72,24.66 Z \
           M 41.02,22.46 \
           C 41.87,22.46 42.56,21.77 42.56,20.92 \
             42.56,20.07 41.87,19.38 41.02,19.38 \
             40.16,19.38 39.47,20.07 39.47,20.92 \
             39.47,21.77 40.16,22.46 41.02,22.46 Z \
           M 37.15,20.96 \
           C 38.00,20.96 38.70,20.26 38.70,19.41 \
             38.70,18.56 38.00,17.87 37.15,17.87 \
             36.30,17.87 35.61,18.56 35.61,19.41 \
             35.61,20.26 36.30,20.96 37.15,20.96 Z \
           M 32.32,20.30 \
           C 33.17,20.30 33.87,19.61 33.87,18.76 \
             33.87,17.91 33.17,17.22 32.32,17.22 \
             31.47,17.22 30.78,17.91 30.78,18.76 \
             30.78,19.61 31.47,20.30 32.32,20.30 Z \
           M 37.77,16.41 \
           C 38.77,16.41 39.59,15.60 39.59,14.60 \
             39.59,13.59 38.77,12.78 37.77,12.78 \
             36.77,12.78 35.95,13.59 35.95,14.60 \
             35.95,15.60 36.77,16.41 37.77,16.41 Z \
           M 32.94,15.71 \
           C 33.94,15.71 34.76,14.90 34.76,13.90 \
             34.76,12.90 33.94,12.09 32.94,12.09 \
             31.94,12.09 31.12,12.90 31.12,13.90 \
             31.12,14.90 31.94,15.71 32.94,15.71 Z \
           M 34.75,40.81 \
           C 35.95,40.81 36.92,39.84 36.92,38.65 \
             36.92,37.45 35.95,36.49 34.75,36.49 \
             33.56,36.49 32.59,37.45 32.59,38.65 \
             32.59,39.84 33.56,40.81 34.75,40.81 Z \
           M 32.32,10.32 \
           C 33.52,10.32 34.48,9.35 34.48,8.16 \
             34.48,6.97 33.52,6.00 32.32,6.00 \
             31.13,6.00 30.16,6.97 30.16,8.16 \
             30.16,9.35 31.13,10.32 32.32,10.32 Z"
  ]
};
