import React from "react";
import Icon from "../components/icon";

function CodeTop() {
  return (
    <div className="absolute top-0 left-0 py-4 px-4 w-1/2 invisible lg:visible">
      <p><Icon className="text-text-highlight1" icon="code" /></p>
      <div className="w-full space-y-2 leading-none">
        <div className="flex">
          <div className="w-32">
            <div className="h-2 bg-highlight1 rounded"></div>
          </div>
          <div className="w-32">
            <div className="h-2 bg-primary rounded"></div>
          </div>
          <div className="w-20">
            <div className="h-2 bg-highlight2 rounded"></div>
          </div>
          <div className="w-32">
            <div className="h-2 bg-primary rounded"></div>
          </div>
        </div>
        <div className="flex">
          <div className="w-36">
            <div className="ml-5 h-2 bg-primary rounded"></div>
          </div>
          <div className="w-20">
            <div className="h-2 bg-highlight3 rounded"></div>
          </div>
          <div className="w-20">
            <div className="h-2 bg-primary rounded"></div>
          </div>
          <div className="w-72">
            <div className="h-2 bg-highlight4 rounded"></div>
          </div>
          <div className="w-10">
            <div className="h-2 bg-primary rounded"></div>
          </div>
        </div>
        <div className="flex">
          <div className="w-40">
            <div className="ml-5 h-2 bg-primary rounded"></div>
          </div>
          <div className="w-40">
            <div className="h-2 bg-highlight1 rounded"></div>
          </div>
        </div>
        <div className="flex">
          <div className="w-16">
            <div className="ml-5 h-2 bg-primary rounded"></div>
          </div>
          <div className="w-28">
            <div className="h-2 bg-highlight5 rounded"></div>
          </div>
          <div className="w-40">
            <div className="h-2 bg-primary rounded"></div>
          </div>
          <div className="w-40">
            <div className="h-2 bg-highlight5 rounded"></div>
          </div>
          <div className="w-20">
            <div className="h-2 bg-primary rounded"></div>
          </div>
        </div>
        <div className="flex">
          <div className="w-8 text-text-highlight2 font-bold mt-auto">
            def
          </div>
          <div className="w-16 mt-auto">
            <div className="h-2 bg-highlight4 rounded"></div>
          </div>
          <div className="w-40 mt-auto">
            <div className="h-2 bg-primary rounded"></div>
          </div>
        </div>
        <div className="flex">
          <div className="w-8">
          </div>
          <div className="w-24">
            <div className="h-2 bg-primary rounded"></div>
          </div>
          <div className="w-20">
            <div className="h-2 bg-highlight4 rounded"></div>
          </div>
          <div className="w-32">
            <div className="h-2 bg-primary rounded"></div>
          </div>
          <div className="w-24">
            <div className="h-2 bg-highlight3 rounded"></div>
          </div>
        </div>
        <div className="flex">
          <div className="w-8">
          </div>
          <div className="w-16">
            <div className="h-2 bg-primary rounded"></div>
          </div>
          <div className="w-20">
            <div className="h-2 bg-highlight2 rounded"></div>
          </div>
          <div className="w-8">
            <div className="h-2 bg-primary rounded"></div>
          </div>
        </div>
        <div className="flex">
          <div className="w-24">
            <div className="h-2 bg-highlight5 rounded"></div>
          </div>
          <div className="w-20">
            <div className="h-2 bg-primary rounded"></div>
          </div>
          <div className="w-48">
            <div className="h-2 bg-highlight1 rounded"></div>
          </div>
          <div className="w-24">
            <div className="h-2 bg-primary rounded"></div>
          </div>
        </div>
        <div className="flex">
          <div className="w-20">
            <div className="h-2 bg-primary rounded"></div>
          </div>
        </div>
        <div className="flex">
          <div className="w-8 text-text-highlight3 font-bold mt-auto">
            map
          </div>
        </div>
        <div className="flex">
          <div className="w-10">
            <div className="h-2 bg-primary rounded"></div>
          </div>
          <div className="w-24">
            <div className="h-2 bg-highlight3 rounded"></div>
          </div>
          <div className="w-40">
            <div className="h-2 bg-primary rounded"></div>
          </div>
          <div className="w-56">
            <div className="h-2 bg-highlight4 rounded"></div>
          </div>
          <div className="w-40">
            <div className="h-2 bg-primary rounded"></div>
          </div>
          <div className="w-16">
            <div className="h-2 bg-highlight2 rounded"></div>
          </div>
        </div>
        <div className="flex">
          <div className="w-80">
            <div className="h-2 bg-primary rounded"></div>
          </div>
        </div>
        <div className="flex">
          <div className="w-28">
            <div className="h-2 bg-primary rounded"></div>
          </div>
        </div>
        <div className="flex">
          <div className="w-6 mt-auto">
            <div className="h-2 bg-primary rounded"></div>
          </div>
          <div className="w-28 mt-auto">
            <div className="h-2 bg-highlight5 rounded"></div>
          </div>
          <div className="w-16 mt-auto">
            <div className="h-2 bg-primary rounded"></div>
          </div>
          <div className="w-20 mt-auto">
            <div className="h-2 bg-highlight2 rounded"></div>
          </div>
          <div className="w-40 mt-auto">
            <div className="h-2 bg-primary rounded"></div>
          </div>
          <div className="w-28 text-primary font-bold mt-auto">
            //
          </div>
        </div>
        <div className="flex">
          <div className="w-28 text-primary font-bold">
            })
          </div>
        </div>
        <div className="flex">
          <div className="w-28">
            <div className="h-2 bg-primary rounded"></div>
          </div>
          <div className="w-28">
            <div className="h-2 bg-highlight5 rounded"></div>
          </div>
          <div className="w-28">
            <div className="h-2 bg-primary rounded"></div>
          </div>
        </div>
        <div className="flex">
          <div className="w-16">
            <div className="h-2 bg-highlight4 rounded"></div>
          </div>
          <div className="w-28">
            <div className="h-2 bg-primary rounded"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CodeTop;
