import React from "react";
import { useThemeStore } from "./store";

const ThemeSwitcher = () => {
  const [theme, setTheme] = useThemeStore();
  const changeTheme = (e) => {
    const { currentTarget } = e;
    const newTheme = currentTarget.getAttribute('data-theme');
    setTheme({theme: newTheme});
  };

  const themeList = {
    retro: "#fdf6e3",
    deep: "#073642",
    classicB: "#000000",
    classicW: "#ffffff",
    dra: "#1F2229"
  };

  function buttonList() {
    return Object.entries(themeList).map(([name, color]) => {
      return <button key={name} data-theme={name} className={`theme-button rounded ${theme.theme === name ? ' is-active' : ''}`} style={{backgroundColor: color}} onClick={changeTheme} aria-label={`Switch to ${name} Theme`}></button>
    });
  };

  return (
    <div className="absolute top-0 right-0 py-4 px-4 invisible lg:visible">
      <div className="switcher">
        {buttonList()}
      </div>
    </div>
  )
}

export default ThemeSwitcher;
