import React from "react";

function CodeBottom() {
  return (
    <div className="absolute bottom-0 left-0 py-4 px-4 w-full invisible md:visible">
      <div className="w-full space-y-2 leading-none">
        <div className="flex">
          <div className="w-auto text-text-highlight4 font-bold">
            function
          </div>
        </div>
        <div className="flex">
          <div className="w-12">
            <div className="h-2 bg-primary rounded"></div>
          </div>
          <div className="w-8">
            <div className="h-2 bg-highlight1 rounded"></div>
          </div>
          <div className="w-5">
            <div className="h-2 bg-primary rounded"></div>
          </div>
        </div>
        <div className="flex">
          <div className="w-10">
            <div className="h-2 bg-primary rounded"></div>
          </div>
        </div>
        <div className="flex">
          <div className="w-10 mt-auto">
            <div className="h-2 bg-primary rounded"></div>
          </div>
          <div className="w-20 mt-auto">
            <div className="h-2 bg-highlight2 rounded"></div>
          </div>
          <div className="w-16 mt-auto">
            <div className="h-2 bg-primary rounded"></div>
          </div>
          <div className="w-48 mt-auto">
            <div className="h-2 bg-highlight4 rounded"></div>
          </div>
          <div className="w-32 mt-auto">
            <div className="h-2 bg-primary rounded"></div>
          </div>
          <div className="w-auto mx-2 text-primary font-bold">
            [  .  ]
          </div>
          <div className="w-28 mt-auto">
            <div className="h-2 bg-highlight5 rounded"></div>
          </div>
          <div className="w-8 mt-auto">
            <div className="h-2 bg-highlight1 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CodeBottom;
